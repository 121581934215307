<template>
  <div class="container wrapper">
    <div class="d-flex justify-center" v-if="!$isMobile()">
      <div
        class="topTab"
        :class="isTalentAgencyRole ? 'col-md-4' : 'col-md-6'"
        @click="currentTab = 'followers'"
      >
        <h1
          class="text-uppercase"
          :class="currentTab === 'followers' ? ' pink2--text' : ''"
        >
          Followers
        </h1>
      </div>
      <div
        class="topTab"
        :class="
          isTalentAgencyRole ? 'col-md-4 text-center' : 'col-md-6 text-right'
        "
        @click="currentTab = 'followings'"
      >
        <h1
          class="text-uppercase"
          :class="currentTab === 'followings' ? ' pink2--text' : ''"
        >
          Followings
        </h1>
      </div>
      <div
        class="col-md-4 text-right topTab"
        v-if="isTalentAgencyRole"
        @click="currentTab = 'talents'"
      >
        <h1
          class="text-uppercase"
          :class="currentTab === 'talents' ? ' pink2--text' : ''"
        >
          Talents
        </h1>
      </div>
    </div>
    <div v-else class="d-flex mobileTopBar">
      <div class="topTab mr-5" @click="currentTab = 'followers'">
        <h2
          class="text-uppercase"
          :class="currentTab === 'followers' ? ' pink2--text' : ''"
        >
          Followers
        </h2>
      </div>
      <div
        class="topTab"
        :class="isTalentAgencyRole ? 'mx-5' : 'ml-5'"
        @click="currentTab = 'followings'"
      >
        <h2
          class="text-uppercase"
          :class="currentTab === 'followings' ? ' pink2--text' : ''"
        >
          Followings
        </h2>
      </div>
      <div
        class="topTab ml-5"
        v-if="isTalentAgencyRole"
        @click="currentTab = 'talents'"
      >
        <h2
          class="text-uppercase"
          :class="currentTab === 'talents' ? ' pink2--text' : ''"
        >
          Talents
        </h2>
      </div>
    </div>
    <AppSearchBar
      v-model="search"
      class="mt-10"
    />
    <div class="mt-10 mb-2">
      ({{
        currentTab !== 'talents'
          ? lists[currentTab].items.length
          : lists[currentTab].length
      }})
    </div>
    <UserContainerList
      :lists="currentTab == 'talents' ? lists[currentTab] : lists[currentTab].items"
      :custom="{
        hasCharacteristic: true,
        nameCol: true
      }"
      :photoKey="currentTab == 'talents' ? 'headshot_file' : 'logo_image'"
    >
      <template #name-col="{ item }" v-if="currentTab != 'talents'">
        <div class="grey--text">
          {{ item.last_name }}
        </div>
        <div>
          {{ item.first_name }}
        </div>
      </template>
      <template #name-col="{ item }" v-else>
        <div class="grey--text">
          {{ item.fname }}
        </div>
        <div>
          {{ item.display_name }}
        </div>
      </template>
    </UserContainerList>
    
    <div class="text-center my-12">
      <v-btn
        class="btn1 mb-3"
        @click="showMoreData"
        v-if="currentTab != 'talents'"
      >
        Show More
      </v-btn>
      <div class="underlineBtn mt-4" @click="$scrollToTop()">Back to top</div>
    </div>
  </div>
</template>

<script>
  import {
    // AUTH,
    PROFILE
  } from '@/api';
  import _ from 'lodash';
  import { uiHelper } from '@/utils';
  import { LOCAL } from '@/constants';
  import { ROUTE_NAME } from '@/constants';
  import { mapGetters } from 'vuex';
  import UserContainerList from '@/components/base/UserContainerList';

  export default {
    name: 'FollowerFollowing',
    components: {
      UserContainerList
    },
    data: () => ({
      me: {},
      lists: {
        talents: [],
        followers: {
          items: []
        },
        followings: {
          items: []
        }
      },
      oriLists: [],
      currentTab: 'followers',
      showMore: {
        followers: 'getUserFollower',
        followings: 'getUserFollowing',
        talents: 'getTalents'
      },
      search: '',
      routeName: ROUTE_NAME
    }),
    computed: {
      ...mapGetters({
        authUser: ['auth/me'],
        race: ['constants/race']
      }),
      isTalentAgencyRole() {
        return this.me?.role == 'agency';
      },
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      }
    },
    watch: {
      search(val) {
        if (val) {
          let selectedList = this.lists[this.currentTab].items;
          const filteredItems = selectedList.filter((x) => {
            const columns = x.first_name + x.last_name;
            return val
              .toLowerCase()
              .split(' ')
              .every((v) => columns.toLowerCase().includes(v));
          });
          this.lists[this.currentTab].items = filteredItems;
          // return this.resources.filter((item)=>{
          //   return item.title.startsWith(this.searchQuery);
          // })
        } else {
          this.lists[this.currentTab].items =
            this.oriLists[this.currentTab].items;
        }
      }
    },
    methods: {
      async loadData() {
        this.$startLoading();
        try {
          const followerData = await PROFILE.getUserFollower(this.me?.id);
          const followingData = await PROFILE.getUserFollowing(this.me?.id);
          if (this.isTalentAgencyRole) {
            const talentsData = await PROFILE.getTalents();
            console.log(talentsData)
            this.lists = { ...this.lists, talents: talentsData?.data?.items };
          }
          this.lists = {
            ...this.lists,
            followers: followerData?.data,
            followings: followingData?.data
          };
          this.oriLists = _.cloneDeep(this.lists);
          this.$closeModal();
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      getImage(user) {
        return (
          user?.logo_image?.media_path ||
          this?.$store?.getters['constants/avatarImageUrl']
        );
      },
      getProfileItems(item) {
        let languages = '';

        if (item?.profile?.languages_spoken) {
          item?.profile?.languages_spoken?.forEach((x, index, arr) => {
            const language = this.constantsData.language_spoken.find(
              (y) => y.value === x
            );
            languages += language.description;
            if (index !== arr.length - 1) languages += ', ';
          });
        }

        return [
          {
            title: 'Height',
            value: item?.profile?.height
          },
          {
            title: 'Race',
            value: item?.profile?.race
              ? this.race.find((x) => x.value == item.profile.race).description
              : null
          },
          {
            title: 'Language',
            value: languages
          },
          {
            title: 'Skills',
            value: item?.profile?.skills
          }
        ];
      },
      async showMoreData() {
        this.$startLoading();
        try {
          const params = {
            perPage: (this.lists[this.currentTab].pagination.per_page += 10)
          };
          const data = await PROFILE[this.showMore[this.currentTab]](
            this.me.id,
            params
          );
          this.lists[this.currentTab] = data?.data;
          this.$closeModal();
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      }
    },
    async mounted() {
      this.currentTab = this.$route.params?.tab || 'followers';
      if (this.$route.query?.id) {
        try {
          const res = await PROFILE.getUserProfileById(this.$route.query?.id);
          this.me = {
            ...res.data.user
          };

          this.$closeModal();
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
      } else {
        this.me = {
          ...this.authUser
        };
      }
      this.$startLoading();
      await this.loadData();
      this.$stopLoading();
    }
  };
</script>

<style scoped>
  .topTab {
    cursor: pointer;
  }

  .mobileTopBar {
    justify-content: space-between;
    overflow-x: auto;
    height: 70px;
    align-items: center;
  }
</style>
